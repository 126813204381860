// #/modules/user.ts

import Simplifi_S3 from '#/services/s3/actions';
import getNewWorkspace from '#/services/workspaces/getNewWorkspace';
import getWorkspace from '#/services/workspaces/getWorkspace';
import getWorkspaces from '#/services/workspaces/getWorkspaces';
import updateWorkspace from '#/services/workspaces/updateWorkspace';
import { IProfile } from '#/types/profile';
import { IWorkspace, workflowFromWorkspace } from '#/types/workspace';

import deleteWorkspace from './workflows/deleteWorkspace';
import updateWorkflow from './workflows/updateWorkflow';
import newWorkspaceFromTemplate from './workspaces/newWorkspaceFromTemplate';

const ETHEREUM_MAINNET_CHAIN_ID = 1;

export interface IUser {
  address: string;
  chainId?: number;
  isAffiliate?: boolean;
}
export class User {
  private address: string;
  private chainId: number;

  public isAffiliate: boolean;

  constructor(user: IUser) {
    this.address = user.address;
    this.chainId = user.chainId ?? ETHEREUM_MAINNET_CHAIN_ID;
    this.isAffiliate = user.isAffiliate ?? false;
  }

  getAddress(): string {
    return this.address;
  }

  // add a method to create or retrieve a user folder
  async createOrRetrieveUserFolder() {
    return await Simplifi_S3.createOrRetrieveUserFolder(this.address);
  }

  async getProfile() {
    return await Simplifi_S3.getProfile(this.address);
  }

  async updateProfile(updatedProfile: IProfile): Promise<boolean> {
    return await Simplifi_S3.updateProfile({
      address: this.address,
      profile: updatedProfile,
    });
  }

  async getWorkspaces(): Promise<IWorkspace[]> {
    return await getWorkspaces({ ownerAddress: this.address });
  }

  async getWorkspace(workspaceUuid: string): Promise<IWorkspace> {
    return await getWorkspace({
      ownerAddress: this.address,
      workspaceUuid,
    });
  }

  async createWorkspace(
    args: {
      name?: string;
      description?: string;
      icons?: string[];
    } = {},
  ): Promise<IWorkspace | null> {
    const { name = 'New workspace', description, icons } = args;
    return await getNewWorkspace({
      ownerAddress: this.address,
      workspaceName: name,
      workspaceDescription: description,
      workspaceIcons: icons,
    });
  }

  async createWorkspaceFromTemplate(args: { template: IWorkspace }) {
    const { template } = args;
    const newWorkspace = await newWorkspaceFromTemplate({
      template,
      newOwnerAddress: this.address,
    });
    console.debug('[createWorkspaceFromTemplate] newWorkspace:', newWorkspace);
    const workspace = await this.updateWorkspace({
      workspaceUuid: newWorkspace?.uuid,
      updatedWorkspace: newWorkspace,
    });
    console.debug('[createWorkspaceFromTemplate] workspace:', workspace);
    return workspace;
  }

  async updateWorkspace(args: {
    workspaceUuid: string;
    updatedWorkspace: Partial<IWorkspace>;
  }): Promise<IWorkspace> {
    const { workspaceUuid, updatedWorkspace } = args;
    const newWorkspace = await updateWorkspace({
      ownerAddress: this.address,
      workspaceUuid,
      updatedWorkspace,
    });
    console.debug('[updateWorkspace] newWorkspace:', newWorkspace);
    const newWorkflow = await updateWorkflow({
      ownerAddress: this.address,
      workflowUuid: workspaceUuid,
      updatedWorkflow: workflowFromWorkspace(newWorkspace),
    })
    return newWorkspace;
  }

  async deleteWorkspace(args: { workspaceUuid: string }) {
    const { workspaceUuid } = args;
    return await deleteWorkspace({ ownerAddress: this.address, workflowUuid: workspaceUuid });
  }
}

// Need to update Api Keys from secrets manager:
export const updateApiKeyInWorkspace = (
  workspace: IWorkspace,
  nodeId: string,
  newApiKey: string,
) => {
  if (workspace.nodes) {
    for (const node of workspace.nodes) {
      if (node.id === nodeId && node.data && 'serviceKey' in node.data) {
        node.data.context = { apiKey: newApiKey };
      }
    }
  }
};
